import { useUser } from "@auth0/nextjs-auth0";
import axios from "axios";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import useApiLink from "../src/reusable/ApiLink";
import { useDetails } from "../src/reusable/CustomHooks";
import ErrorModal from "../src/reusable/ErrorModal";
import Loader from "../src/reusable/Loader";

export default function Home() {
  const router = useRouter();
  const [show, setShow] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [errorWarning, setErrorWarning] = useState("");
  const { user, isLoading } = useUser();
  const { ApiLink } = useApiLink();
  const [userDetails, loading] = useDetails();

  function getIndex() {
    return router.asPath.indexOf("?next=") === -1
      ? router.asPath.indexOf("&next=")
      : router.asPath.indexOf("?next=");
  }

  //redirect for customer
  const next = router.query.next
    ? router.asPath.substr(getIndex() + 6)
    : "/know-how/articles";

  //redirect for partner
  const nextPartner = router.query.next
    ? router.asPath.substr(getIndex() + 6)
    : "/know-how/articles";

  //redirect for candidate
  const nextCandidate = router.query.next
    ? router.asPath.substr(getIndex() + 6)
    : `/know-how/articles`;

  const fetchProfileDetails = (token) => {
    axios
      .get(`${ApiLink}/user/me/view-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async (res) => {
        const storage = window.localStorage;
        await storage.setItem("userDetails", JSON.stringify(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // user is not logged in to auth 0
  useEffect(() => {
    !user && !isLoading && router.push("/api/auth/login");
  }, [user, isLoading]);

  // fetch user details from db after login to auth 0
  /*  useEffect(() => {
    if (!userDetails.token && user && !isLoading) {
      setLoading(true);
      axios
        .get("/api/login")
        .then(function (res) {
          const userInfo = res.data.data;
          const activated = res.data.dataactivated;
          const storage = window.localStorage;
          storage.setItem("userDetails", JSON.stringify(userInfo));
          storage.setItem("token", userInfo.token);
          storage.setItem("user", userInfo.accountType.toLowerCase());
          fetchProfileDetails(userInfo.token);
          if (userInfo.accountType == "CUSTOMER") router.replace(next);
          else if (userInfo.accountType == "PARTNER")
            router.replace(nextPartner);
          else if (userInfo.accountType == "ADMIN")
            router.replace("/admin/know-how/articles");
          else router.replace(nextCandidate);
        })
        .catch(function (err) {
          setLoading(false);
          setShow(true);
          setErrorResponse(err.response?.data?.error);
        });
      setLoading(false);
    } else {
      if (userDetails.accountType == "CUSTOMER") router.replace(next);
      else if (userDetails.accountType == "PARTNER")
        router.replace(nextPartner);
      else if (userDetails.accountType == "ADMIN")
        router.replace("/admin/know-how/articles");
      else router.replace(nextCandidate);
    }
  }, [userDetails, user, isLoading]);
*/
  useEffect(() => {
    if (userDetails.token && user && !isLoading && !loading) {
      // console.log(userDetails)
      if (userDetails.accountType == "CUSTOMER") router.replace(next);
      else if (userDetails.accountType == "PARTNER")
        router.replace(nextPartner);
      else if (userDetails.accountType == "ADMIN")
        router.replace(`/admin/know-how/articles`);
      else router.replace(nextCandidate);
    } else if (!loading && !isLoading && user && !userDetails.token)
      setShow(true);
  }, [userDetails, user, isLoading]);

  return (
    <>
      {show && (
        <ErrorModal
          closeModal={() => {
            setShow(false);
            router.push("/api/auth/logout");
            window.localStorage.clear();
            window.sessionStorage.clear();
          }}
          info={errorResponse}
          errorWarning={errorWarning}
        />
      )}
    </>
  );
}
